#fake-navbar {
	z-index: -1000;
}

.nav-logo {
	max-width: 15rem;
	max-height: none !important;
	height: auto;
}

nav {
	opacity: 0.95;
}

.navbar-burger {
	width: 5rem;
}

.navbar-burger span {
	height: 2px;
	width: 20px;
}

.navbar-menu {
	padding-bottom: 0;
}

.navbar-burger.burger {
	height: auto;
}